import RepositoryFactory from "@/repositories/RepositoryFactory";


const AccountRepository = RepositoryFactory.get("account");
const CompanyRepository = RepositoryFactory.get("company");

export const accountModule = {
  state: () => (
      {
          accounts: []
      }
  ),
  getters: {},
  mutations: {
      setAccounts(state, payload) {
          state.accounts = [];
          payload.forEach(element => state.accounts.push(element));
      },
      setCompany(state, {payload, pk}) {
          const isLargeNumber = (element) => element.id === pk;
          const elem_idx = state.accounts.findIndex(isLargeNumber);
          state.accounts[elem_idx].companies = []
          payload.forEach(element => state.accounts[elem_idx].companies.push(element));
      }
  },
  actions: {
    all({commit}, {ordering, filters}) {
      return new Promise((resolve, reject) => {
          AccountRepository.list({ordering, filters})
              .then(
                  (response) => {
                      commit('setAccounts', response.data);
                      resolve();
                  }
              )
              .catch(
                  (e) => {
                    console.log(e)
                      reject(e);
                  }
              )
          }
      )
    },
      loadCompany({commit}, {pk}) {
        return new Promise((resolve, reject) => {
              CompanyRepository.list(pk)
                  .then(
                      (response) => {
                          commit('setCompany', {payload: response.data, pk});
                          resolve();
                      }
                  )
                  .catch(
                      (e) => {
                        console.log(e)
                          reject(e);
                      }
                  )
              }
          )
      }
  },
  namespaced: true,
};
