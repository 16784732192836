import AccountRepository from "@/repositories/AccountRepository";
import CompanyRepository from "@/repositories/CompanyRepository";

const repositories = {
  account: AccountRepository,
  company: CompanyRepository,
};
export default {
  get: (name) => repositories[name],
};
