<script setup>
import {defineProps, ref} from "vue";
import { Collapse } from 'vue-collapsed'
import CompanySingle from "@/components/CompanySingle.vue";
import PreLoader from "@/components/PreLoader.vue";
import moment from "@/lib/datetime";

const is_preloader = ref(false)
const isExpanded = ref(false)

const props = defineProps(
    {
      instance: JSON
    }
)

</script>

<template>
      <div>
        <div class="title-head">
          <div class="title">
            <h1>{{ props.instance.login }}</h1>
            <div v-if="props.instance.started_at !== null" class="start">старт {{ moment(props.instance.started_at).format('lll') }}</div>
            <div v-if="props.instance.stats.total_sum !== 0">Сумма {{props.instance.stats.total_sum }}</div>
            <div v-if="props.instance.stats.total_count !== 0">Кол-во {{props.instance.stats.total_count }}</div>
            <div v-if="props.instance.stats.one_price !== null">Среднее {{props.instance.stats.one_price }}</div>
            <div v-if="props.instance.balance !== null" class="balance" :class="{negative: (props.instance.balance < 0)}">Баланс {{props.instance.balance }} руб.</div>
          </div>
          <div @click="isExpanded = !isExpanded" class="plusminus" :class="{ active: isExpanded }"></div>
        </div>

        <pre-loader v-if="is_preloader"></pre-loader>
        <Collapse :when="isExpanded">
          <div class="companies">
              <div
                  v-for="company in props.instance.companies"
                  v-bind:key="company.id"
                  class="company"
              >
                <company-single :instance=company></company-single>
                <hr>
              </div>
          </div>
        </Collapse>
        <br>
      </div>
</template>

<style scoped lang="scss">
  hr {
    background-image: linear-gradient(90deg, #688f63, transparent);
    border: 0;
    height: 1px;
  }
  .company{
    margin-bottom: 50px;
    &:last-of-type > hr {
      display: none;
    }
  }
  .companies {
    border: 1px solid #688f63;
    padding: 20px;
    border-radius: 5px;
  }
  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;

    div {
      margin-left: 10px;
    }

    .balance {
      &.negative {
        color: #b73e3e;
      }
      font-weight: bold;
      color: #688f63;
    }
  }

  .title-head {
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;
  }
  .plusminus {
    position: relative;
    width: 25px;
    height: 25px;
    cursor: pointer;

    &.active {
      &:before {
        transform: translatey(-50%) rotate(-90deg);
        opacity: 0;
      }
      &:after {
        transform: translatey(-50%) rotate(0);
      }
    }

    &:before , &:after {
      content: "";
      display: block;
      background-color: #688f63;
      position: absolute;
      top: 50%; left: 0;
      transition: .35s;
      width: 100%;
      height: 3px;
    }

    &:before {
      transform: translatey(-50%);
    }

    &:after {
      transform: translatey(-50%) rotate(90deg);
    }
  }

</style>
