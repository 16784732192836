import { computed } from 'vue'
import { useStore } from 'vuex'

const mapState = () => {
  const store = useStore()

  const x = Object.fromEntries(
    Object.keys(store.state).map(
      key => [key, computed(() => store.state[key])]
    )
  )
  return x
}

const mapGetters = () => {
  const store = useStore()

  return Object.fromEntries(
    Object.keys(store.getters).map(
      getter => [getter, computed(() => store.getters[getter])]
    )
  )
}

const mapMutations = (namespace) => {
  const store = useStore()

  return Object.fromEntries(
    Object.keys(store._mutations).filter(action => action.startsWith(namespace)).map(
      mutation => [mutation.split(namespace + '/')[1], value => store.commit(mutation, value)]
    )
  )
}

const mapActions = (namespace) => {
  const store = useStore()

  return Object.fromEntries(
    Object.keys(store._actions).filter(action => action.startsWith(namespace)).map(
      action => [action.split(namespace + '/')[1], value => store.dispatch(action, value)]
    )
  )
}

export { mapState, mapGetters, mapMutations, mapActions }
