<script setup>

import {mapActions, mapState} from "@/utils/lib";
import {onMounted} from "vue";
import AccountSingle from "@/components/AccountSingle.vue";
import { ref } from 'vue';
import '@vuepic/vue-datepicker/dist/main.css'
import PreLoader from "@/components/PreLoader.vue";
import OrderingApi from "@/components/OrderingApi.vue";
import FilterApi from "@/components/FilterApi.vue";

const startedAtSortAsc = ref(false);
const isPageLoaded = ref(false);

const orderingState = ref();
const filtersState = ref();

const { all } = mapActions('account');

const { account } = mapState();

onMounted(async () => {
  const startedAtSort = startedAtSortAsc.value;
  await refreshItems({startedAtSort})
})

async function refreshItems() {
  isPageLoaded.value = false;

  const ordering = orderingState.value;
  const filters = filtersState.value;

  await all({ordering, filters}).then(() => {
    isPageLoaded.value = true;
  });
}
async function changeSort({ordering, filters}) {
  if (isPageLoaded.value === false) {
    return
  }

  if (ordering !== undefined) {
    orderingState.value = ordering
  }

  if (filters !== undefined) {
    filtersState.value = filters
  }

  await refreshItems()
}


</script>

<template>
  <header>
    <filter-api @selected="changeSort" :isActive="isPageLoaded"></filter-api>
    <ordering-api @clicked="changeSort" :isActive="isPageLoaded"></ordering-api>
  </header>
  <pre-loader v-if="!isPageLoaded"></pre-loader>
  <div v-if="isPageLoaded">
    <div
      v-for="acc in account.accounts"
      v-bind:key="acc.id"
      class="account"
    >
      <account-single :instance=acc></account-single>
    </div>
  </div>

</template>

<style scoped>
.account {
  max-width: 900px;
  margin: auto;
}
</style>
