<template>
  <AccountList></AccountList>
</template>

<script>
import AccountList from "@/components/AccountList.vue";

export default {
  name: "HomePage",
  components: {AccountList}
}
</script>

<style scoped>

</style>