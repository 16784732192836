import HomePage from "@/pages/HomePage";
import {createRouter, createWebHistory} from "vue-router";


const routes = [
  {
    path: "/",
    component: HomePage,
    name: 'Главная',
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  linkExactActiveClass: "active",
  routes,
});

router.beforeEach(() => {
  window.scrollTo(0, 0)
})

export default router;
