import Client from "@/repositories/Client";

const main = "/api/v1/companies";

export default {
  list(pk) {
    return Client.get(
        `${main}/?account=` + pk,
    );
  }
};
