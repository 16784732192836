<script setup>
import {computed, defineProps} from "vue";
import moment from "@/lib/datetime";

const props = defineProps(
    {
      instance: JSON
    }
)

const is_stats = computed(() => props.instance.stats.length > 0)

function* enumerate (it, start = 0) {
  let i = start;
  if (it.length === 0)
    return
  for (const x of it)
    yield [i++, x]
}

function parse_datetime(datetime) {
  return moment(datetime).fromNow();
}

</script>

<template>

  <div>
    <div>
      <h2>{{ props.instance.name }}<span :class="'badge badge-' + props.instance.status_type"> {{ props.instance.status }}</span></h2>
      <h2>Обновлено {{ parse_datetime(props.instance.updated_at) }}</h2>
      <h3>Потрачено сегодня: {{ props.instance.cost_daily }}</h3>
      <h3>Всего: {{ props.instance.cost_total }}</h3>
    </div>
    <div v-if="is_stats">
      <table class="table">
      <tr>
        <th
          v-for="(head, idx) in enumerate(props.instance.stats[0])"
          v-bind:key=idx
        >
          {{ head[1] }}
        </th>
      </tr>
        <tr
            v-for="(row, idx) in enumerate(props.instance.stats.slice(1, -1))"
          v-bind:key=idx
        >
          <td
              v-for="(col, idx) in enumerate(row[1])"
            v-bind:key=idx
          >
            {{ col[1] }}
          </td>
      </tr>
    </table>
    </div>
  </div>
</template>

<style scoped>
h2 {
  display: flex;
}

.table {
	width: 100%;
	margin-bottom: 20px;
	border: 15px solid #F2F8F8;
	border-top: 5px solid #F2F8F8;
	border-collapse: collapse;
}
.table th {
	font-weight: bold;
	padding: 5px;
	background: #F2F8F8;
	border: none;
	border-bottom: 5px solid #F2F8F8;
}
.table td {
	padding: 5px;
	border: none;
	border-bottom: 5px solid #F2F8F8;
}
.badge{
  display: inline-block;
    font-size: 14px;
    margin-left: 20px;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25rem;
    background-color: transparent;
    font-weight: normal;
    padding: 0.45em 0.55em;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    border: 1px solid;
}
.badge-success {
    color: #3eb769;
    border-color: #3eb769;
}
.badge-danger {
    color: #b73e3e;
    border-color: #b73e3e;
}
</style>
