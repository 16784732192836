import axios from "axios";
import {BACKEND_HOST} from "@/constants";


const baseURL = BACKEND_HOST;

const Client = axios.create(
    {
      baseURL,
      headers: {
        "Accept-Language": "ru-RU",
      },
    }
);

export default Client;
