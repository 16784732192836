import { createStore } from "vuex";
import {accountModule} from "@/store/account";

const store = createStore({
  modules: {
    account: accountModule,
  },
});

export default store;
