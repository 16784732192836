<script setup>
import {defineProps, defineEmits} from 'vue';

const states = {
  'disabled': 'asc',
  'asc': 'desc',
  'desc': 'disabled'
}
const props = defineProps(['item', 'isActive'])
const emit = defineEmits(['clicked'])

function click() {
  if (props.isActive === false) {
    return
  }
  const nextState = states[props.item.state]
  emit('clicked', props.item.code, nextState, getValue(nextState))
}


function getValue(nextState) {
  if (nextState === 'disabled') {
    return ''
  }
  if (nextState === 'asc') {
    return `${props.item.code}`
  }
  return `-${props.item.code}`
}

function get_arrow() {
  if (props.item.state=== 'disabled') {
    return ''
  }
  if (props.item.state === 'asc') {
    return '&darr;'
  }
  return '&uarr;'
}

</script>

<template>
  <div @click="click">
    {{ item.text }} <span v-html=get_arrow()></span>
  </div>
</template>

<style scoped>

</style>