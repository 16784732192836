<script setup>
import {defineEmits, defineProps, ref} from 'vue'
import OrderingApiItem from "@/components/OrderingApiItem.vue";

const emit = defineEmits(['clicked'])

const props = defineProps(['isActive'])
const items = ref(
    {
      'started_at': {
        'id': 1,
        'text': 'Дата',
        'code': 'started_at',
        'state': 'disabled',
        'value': '',
      },
      'name': {
        'id': 2,
        'text': 'Название',
        'code': 'name',
        'state': 'disabled',
        'value': '',
      }
    }
)

// function changeSort(key) {
//   const item = items[key]
//   item[key].state = states[item[key.state]]
//   items.forEach(key => this[key])
// }

function onClickChild(code, state, value) {
  items.value[code]['state'] = state
  items.value[code]['value'] = value
  resetOtherKeys(code)
  const ordering = getQueryState()
  emit('clicked', {ordering})
}

function resetOtherKeys(code) {
  Object.keys(items.value).forEach((key) => {
    const item = items.value[key]
    if (key !== code) {
      item.state = 'disabled'
      item.value = ''
    }
  })
}
function getQueryState() {
  let result = []
  Object.keys(items.value).forEach((key) => {
    const item = items.value[key]
    if (item.state !== 'disabled') {
      result.push(item.value)
    }
  })
  if (result.length > 0) {
      return `ordering=${result.join('&')}`
  }
  return ''
}
</script>

<template>
 <div
   v-for="i in items"
   :key="i.id"
 >
   <ordering-api-item :item="i" @clicked="onClickChild" :isActive="props.isActive"></ordering-api-item>
 </div>
</template>

<style scoped>

</style>