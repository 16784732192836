<script setup>
import VueDatePicker from "@vuepic/vue-datepicker";
import {ref, defineEmits, defineProps} from "vue";

const date = ref();
const emit = defineEmits(['selected'])
const props = defineProps(['isActive'])
const handleDate = (modelData) => {
  const dateIso = modelData.toISOString()

  if (date.value && date.value.toISOString() === dateIso) {
    return
  }
  date.value = modelData;
  const filters = `date=${modelData.toISOString()}`
  emit('selected', {filters})
}

</script>

<template>
  <VueDatePicker :disabled='!props.isActive' :model-value="date" text-input @update:model-value="handleDate"/>
</template>

<style scoped>

</style>