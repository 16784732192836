import Client from "@/repositories/Client";

const main = "/api/v1/accounts";

export default {
  list({ordering, filters}) {
    let qs = []

    if (ordering) {
      qs.push(ordering)
    }

    if (filters) {
      qs.push(filters)
    }

    let q = ''

    if (qs.length > 0) {
      q = qs.join('&')
    }
    return Client.get(
        `${main}/?${q}`,
    );
  }
};
